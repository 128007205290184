import axios from 'axios'
import qs from 'qs'
import Crud from '../utils/crud'
const crud = new Crud('/orders')

const state = () => ({
  ...crud.state
})
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  async createDraft (context, { payload }) {
    const cleanedPayload = clean(Object.assign({}, payload))
    return await new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${context.state.endpoint}/draft`,
        data: cleanedPayload,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async updateDraft (context, { id, payload }) {
    const cleanedPayload = clean(Object.assign({}, payload))
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: id ? `${context.state.endpoint}/${id}/draft` : context.state.endpoint,
        data: cleanedPayload,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async autosave (context, { id, payload }) {
    const cleanedPayload = clean(Object.assign({}, payload))
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: id ? `${context.state.endpoint}/${id}/autosave` : context.state.endpoint,
        data: cleanedPayload,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
  async changeOrderToDraft (context, id) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: `${context.state.endpoint}/${id}/changeOrderToDraft`,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  },
}
const mutations = {
  ...crud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

function clean (obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName]
    } else if (typeof obj[propName] === 'object') {
      clean(obj[propName])
    }
  }
  return obj
}
