const OrderList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Orders/List.vue')
const NewOrder = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Orders/New.vue')
const EditOrder = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Orders/Edit.vue')
const ViewOrder = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Orders/View.vue')

export default [
  {
    path: 'ordini',
    name: 'Orders',
    components: { default: OrderList }
  },
  {
    path: 'ordini/nuovo',
    name: 'Nuovo Ordine',
    components: { default: NewOrder }
  },
  {
    path: 'ordini/:id/modifica',
    name: 'Modifica Ordine',
    components: { default: EditOrder }
  },
  {
    path: 'ordini/:id/visualizza',
    name: 'Visualizza Ordine',
    components: { default: ViewOrder }
  }
]
