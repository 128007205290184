const ReservationList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Reservations/List.vue')
const NewReservation = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Reservations/New.vue')
const EditReservation = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Reservations/Edit.vue')
const ViewReservation = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Reservations/View.vue')

export default [
  {
    path: 'prenotazioni',
    name: 'Prenotazioni',
    components: { default: ReservationList }
  },
  {
    path: 'prenotazioni/nuovo',
    name: 'Nuova Prenotazione',
    components: { default: NewReservation }
  },
  {
    path: 'prenotazioni/:id/modifica',
    name: 'Modifica Prenotazione',
    components: { default: EditReservation }
  },
  {
    path: 'prenotazioni/:id/visualizza',
    name: 'Visualizza Prenotazione',
    components: { default: ViewReservation }
  }
]
