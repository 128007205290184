const ScheduleList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Schedule/List.vue')

export default [
  {
    path: 'scadenziario',
    name: 'Schedules',
    components: { default: ScheduleList }
  }
]
