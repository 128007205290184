const QuotationList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Quotations/List.vue')

export default [
  {
    path: 'preventivi',
    name: 'Quotations',
    components: { default: QuotationList }
  }
]
