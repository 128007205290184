import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import auth from '@/store/modules/auth'
import dashboard from '@/store/modules/dashboard'
import salesByProduct from '@/store/modules/salesByProduct'
import salesByCustomer from '@/store/modules/salesByCustomer'
import admins from '@/store/modules/admins'
import agents from '@/store/modules/agents'
import customers from '@/store/modules/customers'
import customerAddresses from '@/store/modules/customerAddresses'
import orders from '@/store/modules/orders'
import paymentMethods from '@/store/modules/paymentMethods'
import shippingMethods from '@/store/modules/shippingMethods'
import states from '@/store/modules/states'
import products from '@/store/modules/products'
import packages from '@/store/modules/packages'
import provinces from '@/store/modules/provinces'
import electronicInvoiceRoutings from '@/store/modules/electronicInvoiceRoutings'
import deliveryNotes from '@/store/modules/deliveryNotes'
import settings from '@/store/modules/settings'
import timetables from '@/store/modules/timetables'
import commissions from '@/store/modules/commissions'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    dashboard,
    salesByProduct,
    salesByCustomer,
    admins,
    agents,
    customers,
    customerAddresses,
    orders,
    paymentMethods,
    shippingMethods,
    states,
    products,
    packages,
    provinces,
    electronicInvoiceRoutings,
    deliveryNotes,
    settings,
    timetables,
    commissions
  }
})

export default store