const ListAgents = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Agents/List.vue')
const EditAgent = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Agents/Edit.vue')

export default [
  // ADMINS
  {
    path: 'agenti',
    name: 'Agenti',
    meta: { needsAdmin: true },
    components: { default: ListAgents }
  },
  {
    path: 'agenti/:id/modifica',
    name: 'Modifica Agente',
    meta: { needsAdmin: true },
    components: { default: EditAgent }
  }
]
