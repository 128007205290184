
import router from '@/routes/router.js'

import secureStorage from '@/utils/secureStorage'

const PermissionsPlugin = {
  install (Vue, options) {
    Vue.prototype.$isAdmin = function () {
      const userObject = JSON.parse(secureStorage.getItem('user'))
      const user = userObject ? userObject.user : false
      if (user) {
        const isAdmin = user.admin
        return isAdmin
      } else {
        return false
      }
    }

    Vue.prototype.$canViewCommissions = function () {
      const userObject = JSON.parse(secureStorage.getItem('user'))
      const user = userObject ? userObject.user : false
      if (user) {
        const canViewCommissions = user.show_commissions
        return canViewCommissions
      } else {
        return false
      }
    }

    router.beforeEach((to, from, next) => {
      if (to.meta && to.meta.needsAdmin) {
        if (!Vue.prototype.$isAdmin()) {
          next('/')
          return
        }
      }
      next()
    })
  }
}

export default PermissionsPlugin
