const ProductList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Products/List.vue')

export default [
  {
    path: 'magazzino',
    name: 'Magazzino',
    components: { default: ProductList }
  }
]
