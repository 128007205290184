const CommissionList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Commissions/List.vue')

export default [
  {
    path: 'provvigioni',
    name: 'Commissions',
    components: { default: CommissionList }
  }
]
