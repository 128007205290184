import axios from 'axios'
import Crud from '../utils/crud'
const crud = new Crud('/products')

const state = () => ({
  ...crud.state
})
const getters = {
  ...crud.getters
}
const actions = {
  ...crud.actions,
  async updateAssignations (context, { id, payload }) {
      return await new Promise((resolve, reject) => {
        axios({
          method: 'PUT',
          url: id ? `${context.state.endpoint}/${id}/assignations` : context.state.endpoint,
          data: payload,
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
          resolve(response)
        }, (error) => {
          reject(error)
        })
      })
    },
  async updateVisibility (context, { id, payload }) {
    return await new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: id ? `${context.state.endpoint}/${id}/updateVisibility` : context.state.endpoint,
        data: payload,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        resolve(response)
      }, (error) => {
        reject(error)
      })
    })
  }
}
const mutations = {
  ...crud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
