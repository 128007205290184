const RevenueList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Revenue/Index.vue')

export default [
  {
    path: 'provvigioni',
    name: 'Revenues',
    components: { default: RevenueList }
  }
]
