const CustomerList = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Customers/List.vue')
const NewCustomer = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Customers/New.vue')
const EditCustomer = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Customers/Edit.vue')

export default [
  {
    path: 'clienti',
    name: 'Clienti',
    components: { default: CustomerList }
  },
  {
    path: 'clienti/nuovo',
    name: 'Nuovo Cliente',
    components: { default: NewCustomer }
  },
  {
    path: 'clienti/:id/modifica',
    name: 'Modifica Cliente',
    components: { default: EditCustomer }
  }
]
